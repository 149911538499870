<template>
  <div class="structural">
    <img-div
      class-name=""
      :img-url="require('@/assets/business/structural/topBanner.png')"
      :height="height"
    >
      <div class="container">
        <div class="p1 mt123">
          {{ $t("business.structural.topBannerTitle") }}
        </div>
        <div class="desc1 mt26">
          {{ $t("business.structural.topBannerDesc1") }}
        </div>
        <div class="desc1 mt12">
          {{ $t("business.structural.topBannerDesc2") }}
        </div>
      </div>
    </img-div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
export default {
  components: {
    ImgDiv,
  },
  data() {
    return {
      height: 0,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getImgHeight();
    window.onresize = () => {
      this.getImgHeight();
    };
  },
  methods: {
    // getImgHeight() {
    //   let clientHeight =
    //     document.body.clientHeight || document.documentElement.clientHeight;
    //   this.height = clientHeight - 250;
    // },
    getImgHeight() {
      const headerHeight =
        document.getElementById("pageHeader").clientHeight || 60;
      const footerHeight =
        document.getElementById("pageFooter").clientHeight || 273;
      console.log(footerHeight);
      this.height = window.screen.availHeight - headerHeight - footerHeight;
    },
  },
};
</script>
<style lang="scss" scoped>
.structural {
  background-color: var(--whiteColor);
}
</style>
